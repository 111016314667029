import { Logger } from 'services/logger';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const logger = new Logger('showHandledErrors');
export interface ShowHandledErrors {
  showUnhandledModal?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function showHandledErrors(
  err: any,
  { showUnhandledModal = true }: ShowHandledErrors = {},
): Promise<void> {
  if (err?.response?.status === 400) {
    const { message } = err.response.data;

    const messages: string[] =
      typeof message === 'string' ? [message] : message;

    logger.warn(`==> bad request`, err);
    showMessageStatus({
      imageSrc: '/images/error-400.svg',
      titleContent: 'Ops, algo deu errado',
      textButton: 'Ok',
      textContent: messages.join('\n'),
    });
    return;
  }

  if (err?.request || !err?.status) {
    console.error(err);
    showMessageStatus({
      imageSrc: '/images/error-400.svg',
      titleContent: 'Ops, algo deu errado',
      textButton: 'Ok',
      textContent: 'Confira a sua conexão com a internet',
    });
    return;
  }

  if (showUnhandledModal) {
    await showUnhandledErrorModal();
  }
  logger.error(`==> showHandledErrors`, err);

  throw err;
}

export const showUnhandledErrorModal = async () => {
  return showMessageStatus({
    imageSrc: '/images/error-400.svg',
    titleContent: 'Ops, algo deu errado',
    textButton: 'Ok',
    textContent:
      'Nossa equipe já foi notificada, por favor tente novamente em alguns minutos.',
  });
};
export type SwalMessageStatusImageType =
  | 'success'
  | 'error'
  | 'question'
  | 'delete';
export type SwalMessageStatusType = {
  imageSrc?: string;
  textButton?: string;
  imageType?: SwalMessageStatusImageType;

  textContent?: string;
  titleContent?: string;
  customClass?: string;
  textCancelButton?: string;
  onIsDenied?: () => void;
  onIsAccepted?: () => void;
  imageAlt?: string;
};
const OPERATION_ICONS = {
  success: '/images/win.svg',
  error: '/images/error-modal.svg',
  question: '/images/question-modal.svg',
  delete: '/images/delete.svg',
};

export async function showMessageStatus({
  titleContent,
  imageSrc,
  imageType,
  textButton,
  textContent,
  customClass,
  textCancelButton,
  onIsAccepted,
  onIsDenied,
  imageAlt,
}: SwalMessageStatusType): Promise<void> {
  if (!imageSrc) {
    if (imageType) {
      imageSrc = OPERATION_ICONS[imageType];
    }
  }
  Swal.fire({
    title: titleContent,
    text: textContent,
    imageUrl: imageSrc,
    imageHeight: 294,
    imageAlt,
    buttonsStyling: false,
    showCancelButton: textCancelButton ? true : false,
    showConfirmButton: textButton ? true : false,
    customClass: {
      confirmButton:
        'bg-green-700 font-medium text-base text-white font-light p-4 font-poppins rounded-md my-1 mx-1',
      title: `${customClass}`,
      cancelButton:
        'bg-red-700 font-medium text-base text-white font-light p-4 font-poppins rounded-md my-1 mx-1',
      container: 'p-12',
      actions: 'flex-row-reverse',
    },
    cancelButtonText: textCancelButton,
    confirmButtonText: textButton,
  }).then(result => {
    if (result.isConfirmed) {
      if (onIsAccepted) {
        onIsAccepted();
      }
    }
    if (result.isDismissed) {
      if (onIsDenied) {
        onIsDenied();
      }
    }
  });
}

export const ReactSwal = withReactContent(Swal);
